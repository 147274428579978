<template>
    <AssitingLivingModule />
</template>

<script>
import AssitingLivingModule from "@/components/admin/modules/AssitingLivingModule";

export default {
    name: "AssitingLiving",
    title: "Gestión de Assisting Living | Baja California Health Tourism",
    components: { 
        AssitingLivingModule 
    },
};
</script>

<style scoped>
</style>
