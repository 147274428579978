<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table :headers="headers" :items="items" :items-per-page="-1" :search="searchAssitingLiving"
                :loading="loadingData" :expanded="expanded" single-expand show-expand item-key="id"
                no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere!"
                class="elevation-1" hide-default-footer>
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''">
                            Assisting Living
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-switch v-model="filterPendings" class="pt-6" :loading="loadingData">
                            <template v-slot:label>
                                Pendientes({{ countPending }})
                            </template>
                        </v-switch>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field id="findtext-assiting-living" label="Buscar.." name="findAssitingLiving"
                                outlined dense single-line clearable background-color="white" hide-details
                                v-model="searchAssitingLiving" class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-btn color="blue darken-1" dark depressed elevation="0" class="mb-2" @click="newItem"
                            v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
                            Adicionar
                        </v-btn>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Est&aacute;s seguro que desea eliminar este elemento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" :disabled="saveChanges" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" :loading="saveChanges" @click="deleteItemConfirm">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeletePicture" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar esta Imagen de la Galer&iacute;a?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" :disabled="saveChanges" text
                                        @click="closeDeletePicture">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" :loading="saveChanges"
                                        @click="deleteItemConfirmPicture">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogError" persistent max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error de Validaci&oacute;n</span>
                                </v-card-title>

                                <v-card-text>
                                    <div class="text-h6 pa-12">{{ messageError }}!</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn text @click.stop="closeDialogError"> Cerrar </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialogGalleryPhotos" persistent
                            width="90%">
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>Administrador de Im&aacute;genes</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn class="hidden-sm-and-down" icon dark
                                            @click="dialogGalleryPhotos = false">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <!--v-model="model"-->
                                                <v-slide-group center-active class="pa-0"
                                                    next-icon="mdi-arrow-right-drop-circle-outline"
                                                    prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                                                    <v-slide-item v-for="itemImage in editedItem.pictures"
                                                        :key="itemImage.id" v-slot="{ active, toggle }">
                                                        <v-card :color="active ? undefined : 'white'"
                                                            class="ma-4 rounded-lg" height="auto" width="250"
                                                            elevation="5" @click="toggle">
                                                            <v-img :src="itemImage.url != null
                                                                    ? itemImage.url
                                                                    : require('@/assets/images/no-image.jpg')
                                                                " :lazy-src="require('@/assets/images/no-image.jpg')
                                    " gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1"
                                                                height="250"
                                                                class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                                                <v-btn dark icon elevation="0" class="ma-2"
                                                                    v-on:click="deletePicture(itemImage)">
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>

                                                                <template v-slot:placeholder>
                                                                    <v-row class="fill-height ma-0" align="center"
                                                                        justify="center">
                                                                        <v-progress-circular indeterminate
                                                                            color="grey lighten-5"></v-progress-circular>
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </v-card>
                                                    </v-slide-item>
                                                </v-slide-group>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-file-input v-model="selectedFiles" small-chips
                                        accept="image/png, image/jpeg, image/bmp" show-size multiple clearable
                                        label="Agregar ficheros" @change="inputChanged">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip small text-color="white" color="#295671" close
                                                @click:close="removeChip(index)">
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <div v-if="imageFiles">
                                        <h5>Archivos seleccionados</h5>
                                        <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                                            {{ f.name }}
                                        </v-chip>
                                        <div class="py-3">
                                            <v-btn dark color="primary" :loading="saveChanges"
                                                @click="uploadPictures()">Actualizar
                                                Galer&iacute;a
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadGalleryImages(item)">
                                mdi-camera
                            </v-icon>
                        </template>
                        <span>Administrar Im&aacute;genes</span>
                    </v-tooltip>

                    <QRCoeprisAssitingLiving :item="item" />

                    <Keywords v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'" :entity="item" :url="urlServices"
                        @reload="reload" />

                    <CertificationAssitingLiving v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'" :entity="item"
                        :url="urlServicesCertification" :deleteUrl="deleteUrlServicesCertification" @reload="reload" />

                    <HistorialChanges :item="item" :roles="user.roles | filterRoles" />

                    <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.active="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.active))">
                        {{ fixStateLabelExtra(item.active) }}
                    </div>
                </template>
                <template v-slot:item.coparmex="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_coparmex))">
                        {{ fixStateLabelExtra(item.state_coparmex) }}
                    </div>
                </template>
                <template v-slot:item.excellence="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_ceetsb))">
                        {{ fixStateLabelExtra(item.state_ceetsb) }}
                    </div>
                </template>
                <template v-slot:item.repsabi="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelExtra(item.state_coepris))">
                        {{ fixStateLabelExtra(item.state_coepris) }}
                    </div>
                </template>
                <template v-slot:item.outstanding="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity))">
                        {{ fixStateLabelYesOrNot(item.typeEntity) }}
                    </div>
                </template>
                <template v-slot:item.premium="{ item }">
                    <div class="custom-state-div custom-circle"
                        :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity, 'P'))">
                        {{ fixStateLabelYesOrNot(item.typeEntity, 'P') }}
                    </div>
                </template>
                <template v-slot:item.member="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity, 'M'))">
                        {{ fixStateLabelYesOrNot(item.typeEntity, 'M') }}
                    </div>
                </template>
                <template v-slot:item.basic="{ item }">
                    <div class="custom-state-div" :style="fixStateColor(fixStateLabelYesOrNot(item.typeEntity, 'B'))">
                        {{ fixStateLabelYesOrNot(item.typeEntity, 'B') }}
                    </div>
                </template>
                <template v-slot:item.count="{ item }">
                    {{ item.historials_count }}
                </template>

                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado!</span>
                    <br />
                    <v-btn color="primary" @click="reload"> Recargar </v-btn>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="10" class="picture-list">
                                    <v-row display="inline-block">
                                        <v-img :src="item.pictures[0] != null
                                                ? item.pictures[0].url
                                                : require('@/assets/images/no-image.jpg')
                                            " :lazy-src="require('@/assets/images/no-image.jpg')" max-height="250"
                                            max-width="250" aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2 picture-list">
                                        </v-img>
                                    </v-row>
                                </v-col>
                                <v-col cols="4">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="4"> Direcci&oacute;n: </v-col>
                                        <v-col>
                                            {{ item.address }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="4"> Email: </v-col>
                                        <v-col>
                                            {{ item.email }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="4"> Descripci&oacute;n - Ingl&eacute;s: </v-col>
                                        <v-col>
                                            {{ item.description_english }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="4"> Sitio Web: </v-col>
                                        <v-col>
                                            {{ item.web_page }}
                                        </v-col>
                                    </v-row>
                                    <v-row :dense="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="6">
                                            Tel&eacute;fono MX:
                                            <a target="_blank" v-if="item.phone !== 'null'"
                                                :href="'tel:+52' + item.phone">+52 {{ item.phone |
                                                formatPhone }}</a>
                                            <span v-else :href="'#'">No cuenta con número de teléfono mexicano</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row :dense="$vuetify.breakpoint.smAndDown" class="mx-1 my-5">
                                <v-col cols="4">
                                    Perfil en Facebook:
                                    <a target="_blank" v-if="item.facebook_profile" :href="item.facebook_profile">Ir a
                                        la red social</a>
                                    <span v-else :href="'#'">No cuenta con esta red social</span>
                                </v-col>
                                <v-col cols="4">
                                    Tipo de Establecimiento:
                                    <span v-if="item.establishment_type">{{ item.establishment_type }}</span>
                                    <span v-else :href="'#'">No cuenta con Tipo de Establecimiento definido.</span>
                                </v-col>
                                <v-col cols="4">
                                    Perfil en Instagram:
                                    <a target="_blank" v-if="item.instagram_profile" :href="item.instagram_profile">Ir a
                                        la red social</a>
                                    <span v-else :href="'#'">No cuenta con esta red social</span>
                                </v-col>
                                <v-col cols="4">
                                    Canal de Youtube:
                                    <div v-if="item.videos && item.videos.length > 0">
                                        <a target="_blank" v-for="video in item.videos" :key="video.id"
                                            :href="video.url">Ir a la red
                                            social</a>
                                    </div>
                                    <span v-else :href="'#'">No cuenta con esta red social</span>
                                </v-col>
                                <v-col cols="4">
                                    Horarios de Atenci&oacute;n: {{ item.servicehours }}
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
                <template v-slot:footer="attrs">
                    <Pagination v-bind="attrs" :reload="reloadData" url="pagination/assiting-living"
                        :filterPendings="filterPendings" @loading-data="catchLoading" @load-data="catchDatas"
                        @pendings-data="catchPendings" :search="searchAssitingLiving" />
                </template>
            </v-data-table>
            <v-snackbar v-model="snackBar" :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" icon @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
import service from "@/providers/AssitingLivingService";
import Keywords from "@/components/keywords/Keywords";
import { changeInformationSEO } from "@/providers/KeywordsServices";
import HistorialChanges from "@/components/historial-changes/HistorialChanges";
import Pagination from "@/components/pagination/Pagination";
import CertificationAssitingLiving from "@/components/certifications/CertificationAssitingLiving";
import QRCoeprisAssitingLiving from "@/components/qr-coepris-assiting-living/QRCoeprisAssitingLiving";

export default {
    name: "AssitingLivingModule",
    components: {
        Keywords,
        HistorialChanges,
        Pagination,
        CertificationAssitingLiving,
        QRCoeprisAssitingLiving
    },
    data: () => ({
        focus: false,
        valid: true,
        snackBar: false,
        snackText: "",
        searchAssitingLiving: "",
        loadingData: false,
        dialogDelete: false,
        dialogError: false,
        dialogDeletePicture: false,
        filterPendings: false,
        dialogGalleryPhotos: false,
        messageError: "",
        currentPicture: undefined,
        selectedFiles: [],
        imageFiles: [],
        items: [],
        expanded: [],
        headers: [
            { text: "", value: "data-table-expand", width: 5 },
            { text: "Id", value: "id", align: " d-none" },
            {
                text: "Nombre",
                align: "start",
                value: "name",
                width: 180
            },
            {
                text: "Dirección",
                value: "address",
                width: 200
            },
            { text: "E-mail", value: "email" },
            {
                text: "Activo",
                value: "active",
                width: 100,
            },
            {
                text: "Coparmex",
                value: "coparmex",
                width: 120,
            },
            {
                text: "Excelencia",
                value: "excellence",
                width: 118,
            },
            {
                text: "Repssabi",
                value: "repsabi",
                width: 120,
            },
            {
              text: "Destacado",
              value: "outstanding",
              width: 118,
            },
            {
                text: "Premium",
                value: "premium",
                width: 110,
            },
            {
                text: "Miembro",
                value: "member",
                width: 110,
            },
            {
                text: "Básico",
                value: "basic",
                width: 110,
            },
            {
                text: "Visitas",
                value: "count",
                width: 100,
            },
            {
                text: "Acciones",
                value: "actions",
                width: 105,
                sortable: false,
                filterable: false,
            },
        ],
        imageRules: [
            (value) =>
                !value ||
                value.size < 2000000 ||
                "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
        ],
        editedIndex: -1,
        editedItem: {},
        user: {},
        saveChanges: false,
        countPending: 0,
        reloadData: false,
    }),
    filters: {
        filterRoles(value) {
            switch (value) {
                case "ADMIN":
                    return ["COPARMEX", "REPSSABI", "CEETSB"];
                case "COPARMEX":
                    return ["COPARMEX", "REPSSABI", "CEETSB"];
                case "COEPRIS":
                    return ["REPSSABI"];
                case "CEETSB":
                    return ["CEETSB"];
                default:
                    return [];
            }
        },
        formatPhone(value) {
            if (value === null) {
                return "";
            }
            return (
                "(" +
                value.substring(0, 3) +
                ") " +
                value.substring(3, 6) +
                "-" +
                value.substring(6)
            );
        },
    },
    computed: {
        urlServices() {
            return `${process.env.VUE_APP_BHC_BASE_URL}/keywords/assiting-living`;
        },
        deleteUrlServicesCertification() {
            return `${process.env.VUE_APP_BHC_BASE_URL}/delete-certification/assiting-living`;
        },
        urlServicesCertification() {
            return `${process.env.VUE_APP_BHC_BASE_URL}/add-certification/assiting-living`;
        },
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogDeletePicture(val) {
            val || this.closeDeletePicture();
        },
    },
    methods: {
        catchDatas(value) {
            this.items = value;
        },
        catchLoading(value) {
            this.loadingData = value
        },
        catchPendings(value) {
            this.countPending = value;
        },
        reload() {
            this.reloadData = !this.reloadData;
        },
        fixStateColor(state) {
            switch (state) {
                case 'Rechazado':
                    return "background-color: #CA2B2BFF;";
                case 'Pendiente':
                    return "background-color: #6E87DBFF;";
                case 'Aprobado':
                    return "background-color: #6bc263;";
                case 'Si':
                    return "background-color: #6bc263;";
                case 'Sí':
                    return "background-color: #6bc263;";
                case 'No':
                    return "background-color: #cf7336;";
                default:
                    return "background-color: #000;";
            }
        },
        fixStateLabel(item) {
            switch (this.user.roles) {
                case 'CEETSB':
                    return item.state_ceetsb;
                case 'COEPRIS':
                    return item.state_coepris;
                case 'COPARMEX':
                    return item.state_coparmex;
                case 'USER':
                    return item.state_coparmex;
                default:
                    return item.state;
            }
        },
        fixStateLabelExtra(state) {
            switch (state !== "") {
                case state !== "":
                    if (state === 0 || state === "Pendiente") {
                        return "Pendiente";
                    } else if (state === 1 || state === "Aprobado") {
                        return "Aprobado";
                    } else {

                        return "Rechazado";
                    }
            }
        },
        fixStateLabelYesOrNot(state, label) {
            switch (state) {
                case label:
                    return "Sí";
                default:
                    return "No"
            }
        },
        newItem() {
            this.$router.push({
                name: "AddAssitingLiving"
            })
        },
        editItem(item) {
            this.$router.push({
                name: "UpdateAssitingLiving",
                params: {
                    id: item.id
                }
            });
        },
        deleteItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.saveChanges = true;
            service.deleteRecord(this.editedItem.id).then((response) => {
                if (response.success) {
                    this.items.splice(this.editedIndex, 1);
                    this.snackText = "Registro eliminado con éxito.";
                    this.reload();
                    this.closeDelete();
                } else {
                    this.snackText = "Un error impidió eliminar el registro!";
                }
            }).catch(() => {
                this.snackText = "Un error impidió eliminar el registro!";
            }).finally(() => {
                this.snackBar = true;
                this.saveChanges = false;
            });
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = {};
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = {};
                this.editedIndex = -1;
            });
        },
        loadGalleryImages(item) {
            this.selectedFiles = [];
            this.imageFiles = [];
            service.getRecord(item.id).then((fetch_data) => {
                this.editedItem = Object.assign({}, fetch_data.value);
            });
            if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
        },
        removeChip(index) {
            this.imageFiles.splice(index, 1);
            this.selectedFiles = [...this.imageFiles];
        },
        inputChanged() {
            this.imageFiles = [...this.selectedFiles];
        },
        uploadPictures() {
            for (let i = 0; i < this.imageFiles.length; i++) {
                let reader = new FileReader();
                reader.onload = () => {
                    this.imagePreview = reader.result;
                };
                reader.readAsDataURL(this.imageFiles[i]);
                this.saveChanges = true;
                service
                    .uploadImage(this.editedItem.id, -1, this.imageFiles[i])
                    .then((result) => {
                        if (result.success) {
                            this.loadGalleryImages(this.editedItem);
                            this.snackText = "Imagen subida al servidor. Galería actualizada!";
                        } else {
                            this.snackText = "Un error impidió subir al menos una imagen al servidor!";
                        }
                    }).catch(() => {
                        this.snackText = "Un error impidió subir al menos una imagen al servidor!";
                    }).finally(() => {
                        this.snackBar = true;
                        this.saveChanges = false;
                    });
            }
        },
        deletePicture(item) {
            this.currentPicture = Object.assign({}, item);
            this.dialogDeletePicture = true;
        },
        deleteItemConfirmPicture() {
            this.saveChanges = true;
            service
                .deleteImage(this.currentPicture.pivot.house_id, this.currentPicture.id)
                .then((result) => {
                    if (result.success) {
                        this.snackText = "Imagen eliminada desde la galería con éxito!";
                        this.loadGalleryImages(this.editedItem);
                        this.closeDeletePicture();
                    } else {
                        this.snackText = "Un error impidió eliminar la imagen desde la galería!";
                    }
                }).catch(() => {
                    this.snackText = "Un error impidió eliminar la imagen desde la galería!";
                }).finally(() => {
                    this.snackBar = true;
                    this.saveChanges = false;
                });
        },
        closeDeletePicture() {
            this.dialogDeletePicture = false;
        },
        closeDialogError() {
            this.dialogError = false;
            this.messageError = "";
        },
    },
    mounted() {
        this.user = this.$store.getters.getUser;
        this.items = [];
        changeInformationSEO();
    },
};
</script>

<style scoped>
.v-btn {
    text-transform: none !important;
}

.custom-state-div {
    padding: 10px;
    color: #fff;
    border-radius: 20px;
    font-weight: 600;
    text-align: center;
    width: max-content;
    justify-self: center;
}

.picture-list {
    border-radius: 10px;
    max-width: 250px;
}
</style>
